import { registerApplication, start } from "single-spa";
import * as isActive from "./js/activityFunction";
import {angularMicroFrontEnds,legacyApp,menuApp} from "./js/app";
import _ from 'lodash';
import * as singleSpa from "single-spa";

registerMenuApp(menuApp);
registerLegacyAngularJSApp(legacyApp);
//registerMicroFrontEndApps(angularMicroFrontEnds);


start({
  urlRerouteOnly: false,
});

// A lot of angularjs libs are compiled to UMD, and if you don't process them with webpack
// the UMD calls to window.define() can be problematic.
function loadWithoutAmd(name) {
  return Promise.resolve().then(() => {
    let globalDefine = window.define;
    delete window.define;
    return System.import(name).then((module) => {
      window.define = globalDefine;
      return module;
    });
  });
}


function registerMenuApp(menuApp)
{
  if (!_.isEmpty(menuApp))
  {
  registerApplication(
    menuApp.name,
    () => System.import(menuApp.name),
    (location)=> isActive.isNavBar(location),
    { domElement: document.getElementById(menuApp.domId) }
    // (location) => activeWhen(location, 'breconfig') 
  );
  }
  else{
    console.log("Menu MicroFrontFrontEnd is not loaded as required info is not provided");
  }

}

function registerLegacyAngularJSApp(legacyApp) 
{

  if (!_.isEmpty(legacyApp))
  {
  registerApplication(
    legacyApp.name,
    () => loadWithoutAmd(legacyApp.name),
    (location) => {
      if(isActive.isLoggedIn()){ 
        return singleSpa.getAppStatus(menuApp.name) == "MOUNTED"  && isActive.isLegacyUI(location)
      }
      else {
      return isActive.isLegacyUI(location)} 
    } ,  
    { domElement: document.getElementById('page-1-container') }
  );
  }
  else{
    console.log("Legacy Angular JS Subropoint Application is not loaded as required info is not provided!!");
  }
}

// function registerMicroFrontEndApps(angularMicroFrontEnds) 
// {

//   if (!_.isEmpty(angularMicroFrontEnds))
//   {
   
//     Object.values(angularMicroFrontEnds).forEach(value => {
//       registerApplication(
//         value.name,
//         () => System.import(value.name),
//         (location) => isActive.microForntendActiveWhen(location, value.path) ,  
//         { domElement: document.getElementById(value.domId) }
//       );
//    });
//   }
//   else{
//     console.log(" No Micro Frontend Info is Provided!!");
//   }
// }

