
import { angularMicroFrontEnds, menuApp } from "./app";

export function isNavBar(location) {
    if(!localStorage.getItem('authLoggedIn') )
    {
        console.log("User not logged inn yet");
        console.log("location.href",location.href);
        //condition for microfrontend URL redirection to login page and store that redirected URL 
        if(location.hash !=="#/authlogin"){
            //Stop redirecting to login page if URL is for LegacyUI because its redirection already exist
            if( location.href.indexOf(angularMicroFrontEnds.map(app => app.path)) === -1)
            return false;
            localStorage.setItem("_url",location.href);
            location.hash="#/authlogin";   
        }
        return false;     
    }
    else if (localStorage.getItem('authLoggedIn')) {
        console.log("Successfully mapped menu");
        return true;
    }
};

export function isLegacyUI(location) {

    if( location.href.indexOf(angularMicroFrontEnds.map(app => app.path)) === -1){
        console.log('Activating Subropoint legacy application', location.href)
        console.log('location.hash ----',location.hash);
        return true;
      } 
    
      return false;

}

export function microForntendActiveWhen(location, path) {
    const locationHash = location.hash;
    console.log("Path inside microfrontend ",path);
    console.log("locationHash is inside microfrontend",locationHash);
    if (locationHash.startsWith(path)) {
        console.log('Activating microfront application with url', path);
        return true;
    }
    console.log('Not loading microfront application with url');
    return false;

}

export function isLoggedIn() {
    return localStorage.getItem('authLoggedIn');

}



