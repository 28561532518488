export const angularMicroFrontEnds = 

[    
    {
        name: '@optum/SubropointUsersMf',
        path: '#/mf',
        domId: 'page-2-container'
    }
];


export const legacyApp =
{
    name: '@optum/SubroPointLegacyAngularjsApp',   
    domId: 'page-1-container'
}


export const menuApp =
{
    name: '@optum/SubropointMenuMf',  
    domId: 'nav-container'
}



